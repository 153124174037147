<template>
    <div>
    
        <v-row align="center" class=" mb-3">
            <v-col cols="12" md="2" >
                <v-btn @click="openAddDialog()" color="primary" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{this.$route.meta.single}}</h3>
                </v-btn>
            </v-col>
            <v-spacer />
            <!-- <v-col cols="12" md="3">
                <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث حسب الاسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
            </v-col> -->
        </v-row>
        <loading v-if="loading"></loading>
        <v-row v-else>
            <v-col v-for="(item,i) in items" :key="i" cols="12" md="3">
                <v-card outlined>
                    <v-container>
                        <h3>
                            <v-toolbar outlined flat>
                                <v-toolbar-title>
                                    {{ item.description }}
                                </v-toolbar-title>
                                <v-spacer/>
                                <v-btn @click="deleteItem(item.id)" icon>
                                    <v-icon color="error">
                                        fi fi-rr-trash
                                    </v-icon>
                                </v-btn>
                            </v-toolbar>
                            </h3>
                            <h3>مقاسات الحاسبة</h3>
                            <v-img :src="$url+'/'+item.imageUrl"/>
                            <h3>مقاسات الموبايل</h3>
                            <v-img :src="$url+'/'+item.mobileImageUrl"/>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <Dialog />
    </div>
    </template>
    
    <script>
    export default {
    
        components: {
            Dialog: () => import("./dialog"),
        },
    
        data() {
            return {
                loading: false,
                searchName:"",
                headers: [
                    {
                        text: "الصورة",
                        value: "imageUrl"
                    },
                    {
                        text: "العنوان",
                        value: "title"
                    },
                    {
                        text: "العيادة",
                        value: "clinic.name"
                    },
                    {
                        text: "الاجراءات",
                        value: "actions"
                    }
                ],
    
                items: []
            }
        },
    
        created() {
            this.getItems()
            setTimeout(() => {
                this.$eventBus.$on(`${this.$route.path}`, () => {
                    this.getItems()
                })
            }, 100);
        },
    
        beforeDestroy() {
            this.$eventBus.$off(`${this.$route.path}`)
        },
    
        methods: {
            async getItems() {
                this.loading = true
                try {
                    let res = await this.$http.get(`HomeCoverImages?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.searchName}`)
                    this.items = res.data
                } catch (err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            },
    
            async deleteItem(id) {
                this.$genericService.swalAlertConfirm({}).then((result) => {
                    if (result.isConfirmed) {
                        this.$genericService
                            .delete(`HomeCoverImages`, id)
                            .then((res) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `تم الحذف بنجاح`,
                                    type: "success",
                                });
                                this.getItems();
                            })
                            .catch((err) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `حصل خطا اثناء الحذف`,
                                    type: "error",
                                });
                            });
                    }
                });
            },
    
            openAddDialog() {
                var item = {
                    dialogType: 'add'
                }
                this.$store.commit('setItemDetails', item)
                this.$store.commit('setDialog')
            },
    
            openEditDialog(item) {
                item.dialogType = 'edit'
                this.$store.commit('setItemDetails', item)
                this.$store.commit('setDialog')
                this.$eventBus.$emit('fill-fields')
            },
        },
    
    }
    </script>
    